import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { DocLinkT } from "../../../types/doc-types";

type InitialDocsStoreStateT = {
  links: {
    userManualLinks: DocLinkT[];
    technicalDocsLinks: DocLinkT[]
  }
}
const initialDocsStoreState: InitialDocsStoreStateT = {
  links: {
    userManualLinks: [],
    technicalDocsLinks: []
  }
};

export const fetchDocsNavLink = createAsyncThunk("docs/build-nav", async() => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_HOST}/docs/build-nav`,
    { withCredentials: true }
  );

  return response.data;
});

export const appDocsSlice = createSlice({
  name: "app-docs-store",
  initialState: initialDocsStoreState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocsNavLink.fulfilled, (state, action) => {
      state.links = action.payload;
    });
  },
});

export default appDocsSlice.reducer;
