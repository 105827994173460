// ** React Imports
import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useState,
} from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Icon, Box, Button, Menu, MenuItem, Divider } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";

import Translations from "../../layouts/components/Translations";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { bulkProductStatusChange, copyProduct } from "../../../store/apps/product";
import TableExporter from "../../table/table-exporter";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../layouts/components/acl/Can";
import { productStatuses, productStatusObj } from "../../../utils/products/data";
import { Chip } from "../../chip";
import { ProductStatusT } from "../../../types/productTypes";
import { useAlertContext } from "../../../hooks/useAlertContext";

interface Props {
  selectedRows: GridRowId[];
  setSelectedRows: Dispatch<SetStateAction<GridRowId[]>>;
  exportData: any[];
  exportFilename: string;
  toggleDeleteProductDialog: () => void;
  setIsProductLoading: Dispatch<SetStateAction<boolean>>;
}

export const ProductTableHeader = (props: Props) => {
  const {
    selectedRows,
    setSelectedRows,
    exportData,
    exportFilename,
    toggleDeleteProductDialog,
    setIsProductLoading
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const ability = useAbility(AbilityContext);
  const { showSuccessAlert, showErrorAlert } = useAlertContext();

  const canCreate = ability.can('create', 'product');

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [statusMenuAnchorEl, setStatusMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const [copying, setCopying] = useState(false);

  const handleMenuOpen = (
    event:
      | ReactMouseEvent<HTMLButtonElement>
      | ReactMouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    if ("name" in event.currentTarget) {
      if (event.currentTarget.name === "list-actions") {
        return setActionsMenuAnchorEl(event.currentTarget);
      }
    }

    return setStatusMenuAnchorEl(event.currentTarget);
  };

    const handleActionsMenuClose = () => setActionsMenuAnchorEl(null);
    const handleStatusMenuClose = () => setStatusMenuAnchorEl(null);

    const handleCopy = async () => {
      setCopying(true);
      setIsProductLoading(true);

      await dispatch(copyProduct(selectedRows as string[]))
        .unwrap()
        .then(() => {
          setSelectedRows([]);
          showSuccessAlert(t("Products duplicated"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to duplicate products"));
        });

      setIsProductLoading(false);
      setCopying(false);
      handleActionsMenuClose();
    };

    const handleStatusChange = async (status: ProductStatusT) => {
      const data = {
        status,
        selectedProducts: selectedRows as string[],
      };
      setIsProductLoading(true);

      await dispatch(bulkProductStatusChange(data))
        .unwrap()
        .then(() => {
          setSelectedRows([]);
          showSuccessAlert(t("Products updated"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to update products"));
        });

      setIsProductLoading(false);
      handleStatusMenuClose();
      handleActionsMenuClose();
    };

    return (
      <Box
        sx={{
          p: 5,
          pt: 7,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="inherit"
            variant="outlined"
            aria-haspopup="true"
            disabled={(selectedRows && selectedRows.length === 0) || !canCreate}
            onClick={handleMenuOpen}
            endIcon={<Icon>expand_more</Icon>}
            aria-expanded={Boolean(actionsMenuAnchorEl) ? "true" : undefined}
            aria-controls={
              Boolean(actionsMenuAnchorEl) ? "product-list-actions" : undefined
            }
            name="list-actions"
          >
            {t("Actions")}
          </Button>

          <Menu
            open={Boolean(actionsMenuAnchorEl)}
            anchorEl={actionsMenuAnchorEl}
            onClose={handleActionsMenuClose}
            id="product-list-actions"
            PaperProps={{
              sx: {
                minWidth: actionsMenuAnchorEl?.offsetWidth,
                maxWidth: actionsMenuAnchorEl?.offsetWidth,
              },
            }}
          >
            <MenuItem onClick={handleCopy}>
              {copying ? t("Copying...") : t("Copy")}
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={handleMenuOpen}
              aria-expanded={Boolean(statusMenuAnchorEl) ? "true" : undefined}
              aria-controls={
                Boolean(statusMenuAnchorEl)
                  ? "product-list-status-change-action"
                  : undefined
              }
            >
              {t("Change status")}
            </MenuItem>

            <Divider />

            <MenuItem onClick={toggleDeleteProductDialog}>
              {t("Delete")}
            </MenuItem>
          </Menu>

          <Menu
            open={Boolean(statusMenuAnchorEl)}
            anchorEl={statusMenuAnchorEl}
            onClose={handleStatusMenuClose}
            id="product-list-status-change-action"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            {productStatuses.map((status) => (
              <MenuItem key={status} onClick={() => handleStatusChange(status)}>
                <Chip
                  skin="light"
                  size="small"
                  label={status}
                  color={productStatusObj[status]}
                  sx={{
                    cursor: "pointer",
                    textTransform: "capitalize",
                    "& .MuiChip-label": { lineHeight: "18px" },
                  }}
                />
              </MenuItem>
            ))}
          </Menu>

          <Box
            gap={3}
            sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            <TableExporter
              disabled={!canCreate}
              {...{ exportData, exportFilename }}
            />

            <Button
              disabled={!canCreate}
              variant="contained"
              component={Link}
              to="/products/new"
              startIcon={<Icon>add</Icon>}
            >
              <Translations text="Add product" />
            </Button>
          </Box>
        </Box>
      </Box>
    );
};
