import { AdminRolesT } from "../configs/acl";
import yup from "../helpers/customYup";

export const adminSchema = yup.object().shape({
  displayName: yup.string().required(),
  email: yup.string().email().required(),
  password: yup
    .string()
    .when("$editMode", ([editMode], schema) =>
      editMode ? schema : schema.required().min(5)
    ),
  isRegistered: yup.boolean().defined(),
  role: yup.string().required(),
});

export interface AdminData extends yup.InferType<typeof adminSchema> {
  avatar?: string;
}

export interface Admin extends AdminData {
  _id: string;
  registerDate?: string;
  role: AdminRolesT; // overwrite type
}
