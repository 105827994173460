import React from "react";
import DocsEditor from "../../../../comps/docs/docs-editor";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Button,
  CardActions,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

type Inputs = {
  title: string;
  content: string;
};

const AddUserManualScreen = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, watch, ...rest } = useForm<Inputs>();

  console.log(rest.getValues());

  return (
    <FormProvider {...{ register, handleSubmit, watch, ...rest }}>
      <Card>
        <CardHeader
          component={() => (
            <Box
              sx={{
                p: 5,
                pb: 0,
                display: "flex",
                flexDirection: "column",
                gap: 5,
              }}
            >
              <Typography variant="h6">{t("Add new document")}</Typography>
              <TextField
                label="Document Title"
                variant="outlined"
                {...register("title")}
              />
            </Box>
          )}
        />

        <CardContent>
          <DocsEditor />
        </CardContent>

        <CardActions>
          <Button variant="contained" sx={{ marginRight: "auto" }}>
            Save
          </Button>
        </CardActions>
      </Card>
    </FormProvider>
  );
};

export default AddUserManualScreen;
