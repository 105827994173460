
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  code: "editor-code",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  image: "editor-image",
  link: "editor-link",
  list: {
    listitem: "editor-listitem",
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
  },
  ltr: "ltr",
  paragraph: "editor-paragraph",
  placeholder: "editor-placeholder",
  quote: "editor-quote",
  rtl: "rtl",
  table: "admin_docs_editor_theme__table",
  tableCell: "admin_docs_editor_theme__tableCell",
  tableCellActionButton: "admin_docs_editor_theme__tableCellActionButton",
  tableCellActionButtonContainer:
    "admin_docs_editor_theme__tableCellActionButtonContainer",
  tableCellEditing: "admin_docs_editor_theme__tableCellEditing",
  tableCellHeader: "admin_docs_editor_theme__tableCellHeader",
  tableCellPrimarySelected: "admin_docs_editor_theme__tableCellPrimarySelected",
  tableCellResizer: "admin_docs_editor_theme__tableCellResizer",
  tableCellSelected: "admin_docs_editor_theme__tableCellSelected",
  tableCellSortedIndicator: "admin_docs_editor_theme__tableCellSortedIndicator",
  tableResizeRuler: "admin_docs_editor_theme__tableCellResizeRuler",
  tableSelected: "admin_docs_editor_theme__tableSelected",
  tableSelection: "admin_docs_editor_theme__tableSelection",
  text: {
    bold: "editor-text-bold",
    code: "editor-text-code",
    hashtag: "editor-text-hashtag",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    strikethrough: "editor-text-strikethrough",
    underline: "editor-text-underline",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
  },
};
