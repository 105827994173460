import React from 'react'
import { useParams } from 'react-router-dom';

const TechnicalDocPage = () => {
  const { id } = useParams();
  console.log(id);

  return (
    <div>TechnicalDocPage</div>
  )
}

export default TechnicalDocPage