import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./docs-editor-toolbar";
import theme from "./docs-editor-theme";
import "./docs-editor-theme.css";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";

function onError(error: Error) {
  console.error(error);
}


const DocsEditor = () => {
  const initialConfig = {
    namespace: "admin-docs-editor",
    theme,
    onError,
  };

  const { register } = useFormContext()

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Box className="editor-container">
        <ToolbarPlugin />
        <Box className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" {...register("content")} />}
            placeholder={
              <div className="editor-placeholder">Enter content...</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
        </Box>
      </Box>
    </LexicalComposer>
  );
};

export default DocsEditor;
