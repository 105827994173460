// ** React Imports
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FieldPath } from "react-hook-form";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LinearProgress, MenuItem, Select } from "@mui/material";

// ** Third Party Imports
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

// ** Custom Components Imports

// ** Utils Imports
import { useAlertContext } from "../../hooks/useAlertContext";

// ** Types Imports
import { Admin, AdminData, adminSchema } from "../../types/adminTypes";
import { Spinner } from "../utilities/spinner";
import { AppDispatch } from "../../store";
import { createAdmin, getAdmin, updateAdmin } from "../../store/apps/admin";
import { supportedAdminRoles } from "../../configs/acl";
import { clearSpace } from "../../helpers/clearSpace";

// ** Utils Imports
interface Props {
  type?: "add" | "edit";
  onEditCallback?: () => void;
}

const defaultValues = {
  displayName: "",
  email: "",
  password: "",
  role: "user",
  isRegistered: false,
};


const AdminForm = (props: Props) => {
  const { type = "add", onEditCallback } = props;

  // ** States
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // ** Hooks
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<AdminData>({
    defaultValues,
    resolver: yupResolver(adminSchema),
    context: {
      editMode: type === "edit",
    },
  });
  const { showErrorAlert, showSuccessAlert } = useAlertContext();
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data: AdminData) => {
    if (type === "add") {
      dispatch(createAdmin(data))
        .unwrap()
        .then(() => {
          navigate("/admin/list");
          showSuccessAlert(t("Admin created"));
        })
        .catch((err) => {
          Promise.reject(err);
          showErrorAlert(t("Failed to create Admin"));
        });
    }

    if (type === "edit") {
      if (!admin) return;

      const callback = () => {
        if (!onEditCallback) {
          navigate("/admin/list");
          return;
        }

        onEditCallback();
      };

      const isEmpty = clearSpace(String(data.password)).length === 0;

      // prevents empty string from being sent for update
      if (isEmpty || !data.password) {
        delete data.password;
      }

      dispatch(updateAdmin({ id: admin._id, data }))
        .unwrap()
        .then(() => {
          callback();
          showSuccessAlert(t("Admin updated"));
        })
        .catch((err) => {
          Promise.reject(err);
          showErrorAlert(t("Failed to update Admin"));
        });
    }
  };

  const handleAccountDetailsReset = () => {
    if (!admin) return;

    Object.keys(defaultValues).forEach((key) => {
      const k = key as FieldPath<AdminData>;
      if (k in admin && k !== "password") {
        setValue(k, admin[k]);
      }
    });
  };

  useEffect(() => {
    if (type === "edit") {
      setLoading(true);

      dispatch(getAdmin(String(params.id)))
        .unwrap()
        .then((res) => {
          const _admin = res.admin;
          setAdmin(_admin);

          // sets values in the form
          Object.keys(defaultValues).forEach((key) => {
            const k = key as FieldPath<AdminData>;

            // password is in encrypted, no need
            if (k in _admin && k !== "password") {
              setValue(k, _admin[k]);
            }
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, params.id, setValue, type]);

  if (loading) {
    return (
      <Box
        sx={{
          height: 400,
          "& .MuiBox-root": {
            height: "inherit",
          },
        }}
      >
        <Spinner />
      </Box>
    );
  }

  if (type === "edit" && !admin) return null;

  return (
    <Card>
      <CardHeader title={type !== "add" ? t("Edit admin") : t("Add admin")} />
      <form>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="displayName"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      value={value}
                      label={t("Display name")}
                      onChange={onChange}
                      error={Boolean(errors.displayName)}
                    />
                  )}
                />
                {errors.displayName && errors.displayName.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.displayName.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      type="email"
                      value={value}
                      autoComplete="off"
                      label={t("Email")}
                      onChange={onChange}
                      error={Boolean(errors.email)}
                    />
                  )}
                />
                {errors.email && errors.email.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.email.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="role"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <InputLabel>{`${t("Role")}`}</InputLabel>
                      <Select
                        label={`${t("Role")}`}
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.role)}
                      >
                        {supportedAdminRoles.map((role, index) => (
                          <MenuItem key={index} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.role && errors.role.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.role.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="admin-password"
                  required
                  error={Boolean(errors.password)}
                >
                  {t("Password")}
                </InputLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <OutlinedInput
                      required
                      value={value}
                      label={t("Password")}
                      autoComplete="new-password"
                      onChange={onChange}
                      id="admin-password"
                      error={Boolean(errors.password)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <Icon>visibility</Icon>
                            ) : (
                              <Icon>visibility_off</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errors.password && errors.password.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.password.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="isRegistered"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      label={t("Is Registered")}
                      sx={errors.isRegistered ? { color: "error.main" } : null}
                      control={
                        <Checkbox
                          required
                          checked={value}
                          onChange={onChange}
                          sx={
                            errors.isRegistered ? { color: "error.main" } : null
                          }
                        />
                      }
                    />
                  )}
                />
                {errors.isRegistered && errors.isRegistered.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.isRegistered.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {isSubmitting && <LinearProgress />}
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ mr: 3 }}
                onClick={handleSubmit(onSubmit)}
              >
                {type !== "add" ? t("Save") : t("Add admin")}
              </Button>
              <Button
                type="reset"
                variant="outlined"
                color="secondary"
                onClick={handleAccountDetailsReset}
              >
                {t("Reset")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );
};

export default AdminForm;
