import React from 'react'
import { useParams } from 'react-router-dom';

const UserManualPage = () => {
  const { id } = useParams();
  console.log(id);

  return (
    <div>UserManualPage</div>
  )
}

export default UserManualPage